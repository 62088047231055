<body class="h-100 d-flex flex-column">
    <!-- NAVIGATION -->
    <nav id="banner">
        <banner-widget [class]="'d-block mx-auto img-fluid'" [name]="bannerName"></banner-widget>
    </nav>
    <nav id="home" class="navbar navbar-expand-md navbar-light bg-white sticky-top border-bottom">
        <div class="container-xl">

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                (click)="isCollapsed = !isCollapsed">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div [collapse]=isCollapsed class="navbar-collapse" id="navbar-content">
                <menu *ngIf="user" class="pl-0" [user]="user"></menu>
            </div>

            <ng-container *ngIf="user && isCollapsed">
                <menu-user [user]="user" (logout)="onLogOut()"></menu-user>
            </ng-container>
        </div>
    </nav>

    <main class="container pt-4 position-relative flex-grow-1">
        <messages-modal></messages-modal>
        <router-outlet></router-outlet>
        <scroll-to-top></scroll-to-top>
    </main>
    <!-- FOOTER -->
    <footer [class.d-none]="!init">
        <div class="bg-dark text-light">
            <div class="container-xl">
                <div class="row p-3 text-center">
                    <div class="col-12 col-md-6 text-md-left mb-1">
                        <a class="text-light text-decoration-none" routerLink="event">Wydarzenia</a> |
                        <a class="text-light text-decoration-none" [href]="environments.urlContact">Kontakt</a> |
                        <a class="text-light text-decoration-none" [href]="environments.urlBlog">Blog</a> |
                        <a class="text-light text-decoration-none" [href]="environments.urlRegulation">Regulamin</a> |
                        <a class="text-light text-decoration-none" [href]="environments.urlPolicy">Polityka
                            prywatności</a>

                    </div>

                    <div class="col-12 col-md-3 text-md-center mb-1">
                        <a class="text-light text-decoration-none mr-2" [href]="environments.urlFacebook">
                            <icon-b width="32" height="32" name="facebook"></icon-b>
                        </a>
                        <a class="text-light text-decoration-none" [href]="environments.urlInstagram">
                            <icon-b width="32" height="32" name="instagram"></icon-b>
                        </a>
                    </div>
                    <div class="col-12 col-md-3 text-md-right">
                        <a class="text-light text-decoration-none" [href]="'mailto:' + environments.email">Copyright ©
                            {{environments.host}} {{environments.version}}</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</body>
<cookie-bar></cookie-bar>