<ul class="navbar-nav">
    <ng-container *ngIf="user != null">
        <li class="nav-item">
            <a class="nav-link" routerLink="event" routerLinkActive="active underline">Wydarzenia</a>
        </li>
        <li class="nav-item" *ngIf="user?.isIndividual">
            <a class="nav-link" routerLink="order" routerLinkActive="active underline">Moje zamówienia</a>
        </li>
    </ng-container>
    <li class="nav-item">
        <a class="nav-link" [href]="environments.urlContact">Kontakt</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [href]="environments.urlBlog">Blog</a>
    </li>
</ul>
