import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'number-of-items',
  templateUrl: './number-of-items.component.html',
  styleUrls: ['./number-of-items.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberOfItemsComponent implements OnInit {

  @Input() width = '16';
  @Input() height = '16';
  @Input() fontSize = '12';
  @Input() amount = 0;
  @Input() min = 0;
  @Input() max = 1000;

  minDisabled = true;
  maxDisabled = true;

  @Output() plusClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() minusClicked: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
    if (this.amount === null || this.amount === undefined) {
      this.amount = 0;
    }
    this.minMaxCaluculate();
  }

  onMinusClick() {
    if (!this.minDisabled) {
      if (this.amount > 0) { this.amount--; }
      this.plusClicked.emit(this.amount);
      this.minMaxCaluculate();
    }
  }

  onPlusClick() {
    if (!this.maxDisabled) {
      this.amount++;
      this.plusClicked.emit(this.amount);
      this.minMaxCaluculate();
    }
  }

  minMaxCaluculate(): void {
    this.minDisabled = this.min >= this.amount;
    this.maxDisabled = this.max <= this.amount;
  }
}
