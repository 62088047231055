<div class="btn-group">
    <label class="btn btn-secondary btn-sm m-0" (click)="onMinusClick()" [class.disabled]="minDisabled">
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" class="bi bi-dash" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
        </svg>
    </label>
    <label class="btn btn-secondary btn-sm m-0" [style.font-size.px]="fontSize" [style.width.px]="35">
        {{amount}}
    </label>
    <label class="btn btn-secondary btn-sm m-0" (click)="onPlusClick()" [class.disabled]="maxDisabled">
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
    </label>
</div>