import { Component, OnInit, ChangeDetectionStrategy, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Event } from 'src/app/event/interfaces/event.interface';
import { Offer } from 'src/app/offer/interfaces/offer.interface';
import { Photo } from '../../interfaces/photo.interface';
import { PhotoService } from '../../services/photo.service';
import { BasketService } from 'src/app/basket/services/basket.service';
import { Product } from 'src/app/product/interfaces/product.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoModalComponent implements OnInit, OnDestroy {
  photo!: Photo;
  event!: Event;
  photos: Photo[] = [];
  subject!: Subject<Photo>;
  currentIndex!: number;
  src!: string;
  offers: Offer[] = [];

  private subscription: Subscription = new Subscription();

  constructor(private bsModalRef: BsModalRef, private router: Router, private basketService: BasketService,
    private photoService: PhotoService) { }

  ngOnInit(): void {
    this.subscription.add(this.offerRefreshSubscription());
    this.src = this.photoService.getFileUrl(this.photo);
  }

  private offerRefreshSubscription(): Subscription {
    return this.photoService.postOfferRefreshment(this.photo.idPhoto, this.basketService.basket).pipe(
      tap(offerRefreshment => this.basketService.refreshBasket(offerRefreshment.basket)),
      map(offerRefreshment => {
        const retOffers: Offer[] = offerRefreshment.offers;
        retOffers.forEach(offer => this.basketService.updateOfferAmount(offer, this.event, this.photo));
        return retOffers;
      })
    ).subscribe(offers => this.offers = offers);
  }

  onProductChange(product: Product): void {
    setTimeout(() => this.subscription.add(this.offerRefreshSubscription()), 10);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') { this.onPrev(); }
    if (event.key === 'ArrowRight') { this.onNext(); }
  }

  scrollToLastUpdate(str: string) {
    setTimeout(() => {
      const el = this.getElementsByText(str, 'td')[0];
      if (el) { el.scrollIntoView({ behavior: 'smooth', block: 'center' }); }
    }, 200);
  }

  getElementsByText(str: string, tag: string = 'a') {
    return Array.prototype.slice.call(document.getElementsByTagName(tag)).filter(el => el.textContent.trim() === str.trim());
  }

  onPrev() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.photos.length - 1;
    }
    else {
      this.currentIndex -= 1;
    }
    this.photo = this.photos[this.currentIndex];
    this.ngOnInit();
  }

  onNext() {
    if (this.currentIndex === this.photos.length - 1) {
      this.currentIndex = 0;
    }
    else {
      this.currentIndex += 1;
    }
    this.photo = this.photos[this.currentIndex];
    this.ngOnInit();
  }

  onBasketClick() {
    this.onCancel();
    this.router.navigate(['basket']);
  }

  onCreate() {
    this.bsModalRef.hide();
    this.subject.next(this.photo);
    this.subject.complete();
  }

  onCancel() {
    this.bsModalRef.hide();
    //this.subject.next(undefined);
    this.subject.complete();
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

}
