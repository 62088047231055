import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Event } from 'src/app/event/interfaces/event.interface';
import { Photo } from 'src/app/photo/interfaces/photo.interface';
import { Product } from 'src/app/product/interfaces/product.interface';
import { Offer } from '../../interfaces/offer.interface';
import { OfferIsOpenService } from '../../services/offer-is-open.service';

@Component({
  selector: 'offer-list-item',
  templateUrl: './offer-list-item.component.html',
  styleUrls: ['./offer-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferListItemComponent implements OnInit {

  @Input() offer!: Offer;
  @Input() photo!: Photo;
  @Input() event!: Event;
  @Input() isOpen = false;
  @Output() productChange: EventEmitter<Product> = new EventEmitter<Product>();
  offerAmountsSum = 0;
  iconClass = '';

  constructor(private offerIsOpenService: OfferIsOpenService) { }

  ngOnInit(): void {
    this.offerAmountsSum = this.getSumAmountByOffer(this.offer);
    const filterItem = this.offerIsOpenService.items.find(res => res.idOffer === this.offer.idOffer && res.idPhoto === this.photo.idPhoto);
    if (filterItem) { this.isOpen = filterItem.isOpen; }
  }

  isOpenChange(status: boolean): void {
    if (status) {
      this.iconClass = 'rotate-180';
    } else {
      this.iconClass = 'rotate-0';
    }

    this.offerIsOpenService.update(this.photo.idPhoto, this.offer.idOffer, status);
  }

  onProductChange(product: Product): void {
    const productIndex: number = this.offer.products.findIndex(result => result.idProduct === product.idProduct);
    if (this.offer.products[productIndex]) {
      this.offer.products[productIndex] = product;
    }

    const findProduct = this.offer.products.find(result => result.idProduct === product.idProduct);
    if (findProduct) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }

    this.offerAmountsSum = this.getSumAmountByOffer(this.offer);
    this.productChange.emit(product);
  }

  getSumAmountByOffer(offer: Offer): number {
    let amount = 0;
    offer.products.forEach((product) => {
      if (product.amount) {
        if (product.amount > 0) { amount += product.amount; }
      }
    });
    return amount;
  }

}
