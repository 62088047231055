import { Basket } from '../interfaces/basket.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/app/shared/services/abstract.service';

@Injectable({ providedIn: 'root' })
export class BasketApiService extends AbstractService<Basket> {

  constructor(protected http: HttpClient) {
    super(http);
    this.url += '/basket';
  }


}
