<div class="modal-body clearfix">
    <div class="modal-header border-0 p-0">
        <icon-b class="close pull-right hand" width=30 height=30 aria-label="Close" name="x" (click)="onCancel()">
        </icon-b>
    </div>
    <div class="modal-text">
        <div class="row">
            <div class="col-12 col-lg-6 text-center mb-2">
                <photo-modal-image [photo]="photo" [src]="src" [arrowEnabled]="photos.length > 1" (prev)="onPrev()"
                    (next)="onNext()"></photo-modal-image>
            </div>
            <div class="col-12 col-lg-6">
                <offer-list [photo]="photo" [event]="event" [offers]="offers" (productChange)="onProductChange($event)">
                </offer-list>
                <div class="mt-2">
                    <button class="btn btn-primary" (click)="onCancel()">Wróć</button>
                    <basket-button [class]="'btn btn-primary float-right basket-button'" (clicked)="onBasketClick()">
                    </basket-button>
                </div>
            </div>
        </div>
    </div>
</div>