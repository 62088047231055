import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferIsOpenService {
  subject: BehaviorSubject<OfferIsOpenInterface[]> = new BehaviorSubject<OfferIsOpenInterface[]>([]);
  items: OfferIsOpenInterface[] = [];

  update(idPhoto: string, idOffer: string, isOpen: boolean): void {
    const findIndex = this.items.findIndex(result => result.idOffer === idOffer && result.idPhoto === idPhoto);
    if (findIndex > -1) {
      this.items[findIndex].isOpen = isOpen;
    } else {
      const item: OfferIsOpenInterface = new OfferIsOpenModel();
      item.idPhoto = idPhoto;
      item.idOffer = idOffer;
      item.isOpen = isOpen;
      this.items.push(item);
    }
    this.subject.next(this.items);
  }

  constructor() { }
}

export class OfferIsOpenInterface {
  idPhoto!: string;
  idOffer!: string;
  isOpen!: boolean;
}

export class OfferIsOpenModel implements OfferIsOpenInterface {
  idPhoto = '';
  idOffer = '';
  isOpen = false;
}
