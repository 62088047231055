<div *ngIf="photo" class="row border p-2 mt-1">
    <div class="col-12 col-md-2 mb-3 text-center">
        <img class="img-thumbnail hand" [attr.data-src]="src" [isBlob]="true" lazyLoadingImage
            (click)="onPhotoSelected(photo)">
            <p class="card-text">{{photo.name}}</p>
    </div>
    <div class="col-12 col-md-10">
        <table class="table table-borderless">
            <tr *ngFor="let basketItem of basketItems$ | async">
                <ng-container *ngIf="basketItem.amount > 0">
                    <td class="p-1 align-middle" [style.width.%]="33">{{basketItem.product.name}}</td>
                    <td class="p-1 align-middle text-center">
                        <number-of-items [amount]="basketItem.amount" [min]="basketItem.product.min"
                            [max]="basketItem.product.max" (plusClicked)="onChangeAmount(basketItem.product,$event)"
                            (minusClicked)="onChangeAmount(basketItem.product,$event)"></number-of-items>
                    </td>
                    <td class="p-1 align-middle text-left" [style.width.%]="33">
                        <div *ngIf="basketItem.amount > 1; else amountOne" class="row">
                            <div class="col-12">{{basketItem.price*basketItem.amount | currency:'':''}}zł</div>
                            <div class="col-12">za sztukę {{basketItem.price | currency:'':''}}zł</div>
                        </div>
                        <ng-template #amountOne>{{basketItem.price*basketItem.amount | currency:'':''}}zł</ng-template>
                    </td>
                    <td class="p-1 align-middle text-left" [style.width.%]="5"
                        *ngIf="!((basketItem.amount === basketItem.product.min) && (basketItem.amount === basketItem.product.max))">
                        <icon-b [name]="'trash'" class="hand" (click)="onTrashClick(basketItem)"></icon-b>
                    </td>
                </ng-container>
            </tr>
        </table>
    </div>
</div>
