import { BasketApiService } from './basket-api.service';
import { BasketStorageService } from './basket-storage.service';
import { Event } from 'src/app/event/interfaces/event.interface';
import { Offer } from './../../offer/interfaces/offer.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BasketItem } from '../interfaces/basket-item.interface';
import { BasketSummary } from '../interfaces/basket-summary';
import { Basket } from '../interfaces/basket.interface';
import { BasketModel } from '../models/basket.model';
import { map } from 'rxjs/operators';
import { Api } from 'src/app/shared/interfaces/api.interface';
import { Photo } from 'src/app/photo/interfaces/photo.interface';
import { BasketSummaryModel } from '../models/basket-summary.model';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  subjectBasket: BehaviorSubject<Basket> = new BehaviorSubject<Basket>(new BasketModel());
  subjectSummary: BehaviorSubject<BasketSummary> = new BehaviorSubject<BasketSummary>(new BasketSummaryModel());
  basket: Basket = new BasketModel();

  private subscription: Subscription = new Subscription();

  constructor(private basketStorageService: BasketStorageService, private basketApiService: BasketApiService) { }

  loadBasket(): void {
    this.basket = this.basketStorageService.getStorage();
    this.subscription.add(this.postSubscription(this.basket));
  }

  refreshBasket(basket: Basket) {
    this.basket = basket;
    this.subjectBasket.next(this.basket);
    this.subjectSummary.next(this.calculate());
    this.basketStorageService.saveStorage(this.basket);
  }

  update(item: BasketItem, updateApi: boolean): void {
    const itemIndex: number = this.findIndex(item);
    if (itemIndex > -1) {
      this.basket.basketItems[itemIndex] = item;
    } else {
      if (this.basket.basketItems) { this.basket.basketItems.push(item); }
    }
    if (updateApi) { this.subscription.add(this.postSubscription(this.basket)); }
  }

  delete(basketItem: BasketItem): void {
    this.basket.basketItems = this.basket.basketItems.filter(item => item !== basketItem);
    this.subscription.add(this.postSubscription(this.basket));
  }

  calculate(): BasketSummary {
    let sumPrice = 0;
    let sumAmount = 0;
    if (this.basket.basketItems) {
      this.basket.basketItems.forEach((item) => {
        sumAmount += item.amount;
        sumPrice += item.amount * item.price;
      });
    }
    return { sumPrice, sumAmount };
  }

  clear(withStorage: boolean): void {
    this.basket = new BasketModel();
    this.subjectBasket.next(new BasketModel());
    this.subjectSummary.next(new BasketSummaryModel());
    if (withStorage) { this.basketStorageService.clearStorage(); }
  }

  updateOfferAmount(offer: Offer, event: Event, photo: Photo): Offer {
    const retOffer: Offer = offer;
    offer.products.forEach((product, productIndex) => {
      if (this.basket.basketItems) {
        this.basket.basketItems.forEach(item => {
          if (product.idProduct === item.product.idProduct && event.idEvent === item.event.idEvent &&
            photo.idPhoto === item.photo.idPhoto) {
            if (retOffer.products[productIndex].price === item.price) {
              retOffer.products[productIndex].amount = item.amount;
            }
          }
        });
      }
    });
    return retOffer;
  }

  private postSubscription(basket: Basket): Subscription {
    basket.basketItems = basket.basketItems.filter(basketItem => basketItem.amount > 0)
    return this.basketApiService.post(basket).pipe(map((api: Api<Basket>) => api.item)).subscribe(result => {
      this.refreshBasket(result);
    });
  }

  private findIndex(item: BasketItem): number {
    if (this.basket.basketItems !== undefined) {
      return this.basket.basketItems.findIndex(value =>
        item.product.idProduct === value.product.idProduct && item.photo.idPhoto === value.photo.idPhoto
      );
    } else {
      return -1;
    }
  }
}
