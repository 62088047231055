import { OfferRefreshment } from './../../offer/interfaces/offer-refreshment.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/app/shared/services/abstract.service';
import { Photo } from '../interfaces/photo.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Api } from 'src/app/shared/interfaces/api.interface';
import { Basket } from 'src/app/basket/interfaces/basket.interface';
import { EnvironmentNameHelper } from 'src/app/shared/helpers/environment-name.helper';

@Injectable({ providedIn: 'root' })
export class PhotoService extends AbstractService<Photo> {

  constructor(protected http: HttpClient) {
    super(http);
    this.url += '/photo';
  }

  getFileUrl(photo: Photo): string {
    if (EnvironmentNameHelper.useMock()) {
      return `https://picsum.photos/id/${photo.idPhoto}/${photo.width}/${photo.height}`;
    } else {
      return this.url + '/' + photo.idPhoto + '/image';
    }
  }

  getFile(photo: Photo): Observable<string> {
    return this.http.get(this.getFileUrl(photo), { responseType: 'blob', observe: 'response' }).pipe(
      map(api => api.body),
      map(body => {
        if (body) {
          const blob = new Blob([body], { type: 'image/jpg' });
          return URL.createObjectURL(blob);
        } else {
          return '';
        }
      })
    );
  }

  getFileFromUrl(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(
      map(api => api.body),
      map(body => {
        if (body) {
          const blob = new Blob([body], { type: 'image/jpg' });
          return URL.createObjectURL(blob);
        } else {
          return '';
        }
      })
    );
  }

  postOfferRefreshment(idPhoto: string, basket: Basket): Observable<OfferRefreshment> {
    const basketClone: any = JSON.parse(JSON.stringify(basket));
    basketClone.basketItems = basket.basketItems.filter(basketItem => basketItem.amount > 0)
    if (!EnvironmentNameHelper.useMock()) {
      basket.basketItems.forEach((basketItem, index) => {
        if (basketItem.event) { delete basketClone.basketItems[index].event; }
      });
    }
    return this.http.post<Api<OfferRefreshment>>(this.url + '/' + idPhoto + '/offer-refreshment', basketClone).pipe(
      map(api => api.item)
    );
  }
}
