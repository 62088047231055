import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Event } from 'src/app/event/interfaces/event.interface';
import { Photo } from 'src/app/photo/interfaces/photo.interface';
import { Product } from 'src/app/product/interfaces/product.interface';
import { Offer } from '../../interfaces/offer.interface';

@Component({
  selector: 'offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferListComponent implements OnInit {

  @Input() offers!: Offer[];
  @Input() photo!: Photo;
  @Input() event!: Event;
  @Output() productChange: EventEmitter<Product> = new EventEmitter<Product>();

  ngOnInit(): void { }

  onProductChange(product: Product): void {
    this.productChange.emit(product);
  }

}
