import { Injectable } from '@angular/core';
import { Basket } from '../interfaces/basket.interface';
import { BasketModel } from '../models/basket.model';
import { UserService } from 'src/app/user/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class BasketStorageService {

    constructor(private userService: UserService) { }

    public getStorageName(): string {
        let storageName = 'basket';
        if (this.userService.subject.value) { storageName += '-' + this.userService.subject.value.login; }
        return storageName;
    }

    public saveStorage(basket: Basket): void {
        const storageName = this.getStorageName();
        if (storageName !== 'basket') {
            localStorage.setItem(this.getStorageName(), JSON.stringify(basket));
        }
    }

    public getStorage(): Basket {
        const storageBasket = localStorage.getItem(this.getStorageName());
        if (storageBasket != null && storageBasket.length > 2) {
            return JSON.parse(storageBasket);
        } else {
            return new BasketModel();
        }
    }

    public clearStorage(): void {
        localStorage.removeItem(this.getStorageName());
    }
}
