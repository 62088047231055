<basket-list 
*ngFor="let event of events$ | async" 
[event]="event"  
[attr.class]="'d-block mb-3'">
</basket-list>

<h1>Podsumowanie</h1>
<div class="row mb-4" *ngIf="(basketSummary$ | async) as summary">
    <div class="col-6" *ngIf="summary.sumAmount>0; else emptyTemplate">
        <div *ngIf="summary.sumAmount">Ilość: <span class="font-weight-bold">{{ summary.sumAmount }}</span> szt.</div>
        <div *ngIf="summary.sumPrice">Do zapłaty: <span class="font-weight-bold">{{summary.sumPrice | currency:'':''}}</span> zł</div>
    </div>
    <div class="col-6 btn-group" *ngIf="summary.sumAmount>0">
        <button class="btn btn-primary float-right mr-1" [routerLink]="'../'">Cofnij</button>
        <button class="btn btn-primary float-right" [routerLink]="'/order/create'">Dalej</button>
    </div>
    <ng-template #emptyTemplate>
        <div class="col-12">
            <p>Brak produktów w koszyku</p>
            <button class="btn btn-primary mt-3" [routerLink]="'../'">Cofnij</button>
        </div>
    </ng-template>
</div>
